<template>
  <div class="member">
    <h3>{{ clan.server }} | {{ clan.name }} | {{ members.length }} / 30</h3>
    <div style="width: 90%; max-width: 40em">
      <el-table
        :data="members"
        stripe
        border
        style="width: 100%"
        cell-style="padding: 0"
        header-cell-style="padding: 0; height: 4em;"
      >
        <el-table-column prop="uid" label="头像" align="center" width="61">
          <template #default="scope">
            <div style="height: 50px; padding: 5px 0">
              <el-avatar
                :src="`http://q1.qlogo.cn/g?b=qq&nk=${scope.row.uid}&s=140`"
                :alt="scope.row.uid"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="uid"
          label="QQ"
          align="center"
          min-width="10"
          sortable
        />
        <el-table-column
          prop="name"
          label="昵称"
          align="center"
          min-width="10"
          sortable
        />
        <el-table-column prop="uid" label="指令" align="center" width="50">
          <template #default="scope">
            <el-button
              @click="copyDelMemberCmd(scope.row.uid)"
              size="mini"
              circle
              icon="el-icon-delete"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'

export default {
  data() {
    return {
      clan: {
        gid: 791228255,
        name: '小小甜心',
        server: '日服',
      },
      members: [],
    }
  },
  created() {
    this.$emit('changeGid', this.$route.params.gid)
    DataService.getMember(this.$route.params.gid)
      .then((resp) => {
        this.clan = resp.data.clan
        this.members = resp.data.members
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    copyDelMemberCmd(uid) {
      let text = `退会 ${uid}`
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        let ret = window.clipboardData.setData('Text', text)
        alert('命令复制成功！请发送至群内')
        return ret
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        var textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
          let ret = document.execCommand('copy') // Security exception may be thrown by some browsers.
          alert('命令复制成功！请发送至群内')
          return ret
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex)
          alert('复制失败！')
          return false
        } finally {
          document.body.removeChild(textarea)
        }
      }
    },
  },
  props: ['gid'],
  emits: ['changeGid'],
}
</script>

<style scoped>
.member {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
